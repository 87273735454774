<script setup lang="ts">
import HSForm from '~/components/forms/HSForm.vue'
import UserInfoForm from '~/components/forms/contact/UserInfoForm.vue'
import useAuthStore from '~/stores/auth'
import { useHubSpotUser } from '~/composables/useHubSpotUser'

const config = useRuntimeConfig()
const formId = config.public.hubspotAuthContactFormId
const showStep2 = ref<boolean>(false)
const emit = defineEmits<{ close: [] }>()

const authStore = useAuthStore()
const { hubSpotUser } = useHubSpotUser()

const fullName = computed<string>(() => {
  if (
    hubSpotUser.value &&
    hubSpotUser.value?.firstname &&
    hubSpotUser.value?.lastname
  )
    return `${hubSpotUser.value.firstname} ${hubSpotUser.value?.lastname}`
  if (authStore.user && authStore.user?.firstName && authStore.user?.lastName)
    return `${authStore.user.firstName ?? ''} ${authStore.user?.lastName ?? ''}`
  return authStore.user?.email ?? 'User'
})

withDefaults(
  defineProps<{
    withCloseButton?: boolean
  }>(),
  {
    withCloseButton: true,
  },
)

const LEARN_MORE_ABOUT_PRODUCTS = 'Learn more about our products'

const onFormSubmitted = ({ values }) => {
  const type = Object.keys(values).find((key) =>
    key.includes('/type_of_enquiry'),
  )

  if (type === undefined) return

  const typeEnquiry = values[type]
  showStep2.value =
    typeEnquiry === LEARN_MORE_ABOUT_PRODUCTS && hubSpotUser.value !== null
}

const onReady = (form: HTMLFormElement) => {
  const message = form.querySelector('.hs-richtext.hs-main-font-element')
  if (!message) return

  let newMessage = message.firstChild?.textContent
  newMessage = newMessage?.replace('[NAME]', fullName.value)

  if (!newMessage) return

  message.innerHTML = newMessage
}

const close = () => {
  showStep2.value = false
  emit('close')
}
</script>

<template>
  <HSForm
    class="auth-form h-full"
    :form-id="formId"
    @on-submitted="onFormSubmitted"
    @on-ready="onReady"
    @close="close"
  >
    <template #header>
      <h5 class="text-lg font-semibold mb-2">Get in touch with our team</h5>
    </template>
    <template v-if="showStep2" #success="{ closeForm }">
      <UserInfoForm @close="closeForm" />
    </template>
  </HSForm>
</template>

<style>
.auth-form .bm-custom-form .hs-submit {
  margin-top: auto;
}
</style>
