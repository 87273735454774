<script setup lang="ts">
import Icon from '~/components/common/Icon.vue'
import CommonButton from '~/components/common/Button.vue'
import FormPhoneInput from '~/components/common/FormPhoneInput.vue'
import { Form as VeeForm } from 'vee-validate'
import type { TGeneral } from '~/src/types/common'

type TProps = {
  name: string
  modelValue?: string
  label?: string
  canEdit?: boolean
  updated?: boolean
  isEditable?: boolean
  isSubmitting?: boolean
}

const emit = defineEmits(['update:modelValue', 'updateField', 'onSubmit'])
const props = defineProps<TProps>()

const value = computed({
  get: () => props.modelValue,
  set: (val) => {
    emit('update:modelValue', val)
  },
})

const onSubmit = (values: TGeneral) => {
  emit('onSubmit', {
    key: props.name,
    values: {
      value: values[props.name],
      updated: true,
    },
  })
}

const showEditField = () => {
  emit('updateField', {
    key: props.name,
    values: { isEditable: true },
  })
}
</script>

<template>
  <div class="flex gap-y-3.5 w-full flex-col">
    <span class="font-semibold">{{ label }}</span>
    <div
      v-if="!isEditable"
      class="flex items-center gap-x-2 justify-between pl-7 pr-2"
    >
      <span>{{ value }}</span>
      <button
        v-if="canEdit"
        class="ml-auto hover:text-p-500 transition-colors"
        @click="showEditField()"
      >
        <Icon name="edit-chart" />
      </button>
    </div>

    <template v-if="canEdit">
      <VeeForm v-slot="{ handleSubmit, meta }" as="">
        <form
          v-show="isEditable && !updated"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <FormPhoneInput
            v-model="value"
            :name="name"
            :dropdown-options="{
              showCountryName: true,
              showDialCodeInSelection: false,
              showFlags: false,
            }"
            :input-options="{
              showDialCode: false,
            }"
            without-label
          >
            <template #icon-right>
              <CommonButton
                type="submit"
                class="bg-p-600 h-full border-y border-r border-p-500 py-2.5 px-3 text-sm font-semibold !rounded-l-none !rounded-r disabled:bg-grey-600 disabled:border-grey-600 disabled:cursor-not-allowed"
                :show-spinner="isSubmitting"
                :disabled="!meta.valid || !value || isSubmitting"
              >
                Save
              </CommonButton>
            </template>
            <template #arrow-icon>
              <Icon class="size-4" name="ChevronDown" />
            </template>
          </FormPhoneInput>
        </form>
      </VeeForm>

      <div
        v-if="isEditable && updated"
        class="w-full border border-good flex justify-between rounded h-10 items-center"
      >
        <span class="px-2">{{ value }}</span>
        <div class="border-l border-good bg-good h-full flex items-center px-5">
          <Icon class="size-5" name="check-circle" />
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
:deep(.vue-tel-input) {
  @apply border-none !important;
}

:deep(.vue-tel-input:focus-within) {
  @apply border-none ring-0 ring-offset-0 !important;
}

:deep(.vue-tel-input .vti__dropdown) {
  @apply py-2.5 px-3 bg-card rounded mr-1 font-semibold text-sm w-full max-w-[30%];

  color: #23252c;
}

:deep(.vue-tel-input .vti__dropdown .vti__selection) {
  @apply flex gap-x-1.5 items-center justify-between text-sm;
}

:deep(.vue-tel-input .vti__phone) {
  border: 1px solid !important;

  @apply py-2 px-3 rounded-l rounded-r-none border border-p-500 !important;
}
</style>
