<script setup lang="ts">
import HSForm from '~/components/forms/HSForm.vue'
import SuccessFormIcon from 'assets/icons/success-form.svg'
withDefaults(
  defineProps<{
    withCloseButton?: boolean
  }>(),
  {
    withCloseButton: true,
  },
)
const config = useRuntimeConfig()
const formId = config.public.hubspotRefineInterestsFormId as string
</script>

<template>
  <HSForm
    class="learn-more-products h-full"
    :form-id="formId"
    :with-close-button="withCloseButton"
    success-message="Thank you for refining your interests, our team will be in touch with you shortly."
    @close="$emit('close')"
  >
    <template #header>
      <div class="mb-5 flex items-center gap-x-5">
        <SuccessFormIcon />
        <p class="font-semibold">
          Thank you, our team will be in touch shortly.
        </p>
      </div>
    </template>
  </HSForm>
</template>

<style>
.learn-more-products .bm-custom-form .hs-submit {
  margin-top: auto;
}
</style>
