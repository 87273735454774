<script setup lang="ts">
import type { Component } from 'vue'
import LearnMoreProductsForm from '~/components/forms/contact/LearnMoreProductsForm.vue'
import TypeEnquiryForm from '~/components/forms/contact/TypeEnquiryForm.vue'
import Icon from '~/components/common/Icon.vue'
import type { TGeneral } from '~/src/types/common'

const emit = defineEmits<{ close: [] }>()

const props = withDefaults(
  defineProps<{
    isMarket?: boolean
    withCloseButton?: boolean
  }>(),
  {
    isMarket: false,
    withCloseButton: true,
  },
)

type TTypeEnquiryOption = {
  label: string
  component: Component
  props?: TGeneral
}

const config = useRuntimeConfig()
const formComponent = computed(() => typeEnquiry.value?.component)
const formProps = computed(() => typeEnquiry.value?.props ?? {})

const options: Record<string, TTypeEnquiryOption> = {
  'learn-more-about-products': {
    label: 'Learn more about our products',
    component: LearnMoreProductsForm,
  },
  'using-benchmark-prices-in-contracts': {
    label: 'Using Benchmark prices in contracts',
    component: TypeEnquiryForm,
    props: {
      title: 'Using Benchmark prices in contracts',
      formId: config.public.hubspotPriceInContractsFormId,
    },
  },
  'technical-issue': {
    label: 'A technical issue',
    component: TypeEnquiryForm,
    props: {
      title: 'A technical issue',
      formId: config.public.hubspotTechnicalIssueFormId,
    },
  },
  'governmental-enquiry': {
    label: 'A governmental enquiry',
    component: TypeEnquiryForm,
    props: {
      title: 'A governmental enquiry',
      formId: config.public.hubspotGovernmentalEnquiryFormId,
    },
  },
  'press-enquiry': {
    label: 'A press enquiry',
    component: TypeEnquiryForm,
    props: {
      title: 'A press enquiry',
      formId: config.public.hubspotPressEnquiryFormId,
    },
  },
  'student-looking-for-data': {
    label: 'I am a student looking for data',
    component: TypeEnquiryForm,
    props: {
      title: 'I am a student looking for data',
      formId: config.public.hubspotStudentEnquiryFormId,
    },
  },
}

const typeEnquiry = ref<TTypeEnquiryOption | null>(null)

const selectTypeEnquiry = (option: string) => {
  typeEnquiry.value = options[option]
}

const clearForm = () => {
  typeEnquiry.value = null
}

const close = () => {
  emit('close')
}

onBeforeMount(() => {
  if (!props.isMarket) return
  typeEnquiry.value = options['learn-more-about-products']
})
</script>

<template>
  <div
    v-if="!formComponent"
    data-testid="un-auth-form-selection"
    class="flex flex-col items-center gap-y-2 h-full"
  >
    <h5 class="text-h5 mb-2 font-semibold self-start">How can we help?</h5>
    <div class="flex flex-col size-full">
      <button
        v-for="(option, key) of options"
        :key="key"
        class="border-b-2 w-full py-4 px-3 flex justify-between text-sm font-semibold border-s-300 hover:bg-grey-300 hover:border-grey-400 transition-colors duration-200 active:border-grey-400 active:bg-grey-300 focus:outline-grey-400 focus-visible:outline-grey-400"
        @click="selectTypeEnquiry(key)"
      >
        {{ option.label }}
        <Icon name="chevron-right" class="text-black size-4" />
      </button>
    </div>
  </div>
  <component
    :is="formComponent"
    v-else
    :with-close-button="withCloseButton"
    v-bind="formProps"
    @close="close"
  >
    <template #header>
      <button
        class="flex items-center w-fit gap-2 text-sm font-semibold group hover:text-p-900 transition-colors mb-2"
        @click="clearForm"
      >
        <Icon
          name="chevron-left"
          class="text-black size-3 group-hover:text-p-900 transition-colors"
        />
        <span>Back</span>
      </button>
    </template>
  </component>
</template>

<style>
.un-auth-form .bm-custom-form .hs-submit {
  margin-top: auto;
}
</style>
