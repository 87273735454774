<script setup lang="ts">
import SuccessFormIcon from 'assets/icons/success-form.svg'
import CommonButton from '~/components/common/Button.vue'
import UserInfoField from '~/components/common/UserInfoField.vue'

import { useHubSpotUser } from '~/composables/useHubSpotUser'

defineEmits<{
  close: []
}>()
withDefaults(
  defineProps<{
    withCloseButton?: boolean
  }>(),
  {
    withCloseButton: true,
  },
)

const { hubSpotUser, setUserInfo } = useHubSpotUser()

const fields = reactive({
  email: {
    label: 'Email:',
    value: '',
    canEdit: false,
    updated: true,
    isEditable: false,
    isSubmitting: false,
  },
  phone: {
    label: 'Office No.',
    value: '',
    canEdit: true,
    updated: false,
    isEditable: false,
    isSubmitting: false,
  },
  mobilephone: {
    label: 'Mobile No.',
    value: '',
    canEdit: true,
    updated: false,
    isEditable: false,
    isSubmitting: false,
  },
})

const updateField = ({ key, values }) => {
  fields[key] = {
    ...fields[key],
    ...values,
  }
}

const onSubmit = async ({ key, values }) => {
  const field = fields[key]
  field.isSubmitting = true

  try {
    await setUserInfo({ [key]: values.value })
    updateField({ key, values })

    setTimeout(() => {
      updateField({
        key: key,
        values: {
          isEditable: false,
          isSubmitting: false,
          updated: false,
        },
      })
    }, 5000)
  } finally {
    field.isSubmitting = false
  }
}

watch(
  hubSpotUser,
  (newVal) => {
    if (!newVal) return

    fields.email.value = newVal.email ?? ''
    fields.phone.value = newVal.phone ?? ''
    fields.mobilephone.value = newVal.mobilephone ?? ''
  },
  { immediate: true },
)
</script>

<template>
  <div
    class="flex flex-col justify-between items-center gap-y-2 max-w-full h-full"
  >
    <div class="flex flex-col">
      <div class="mb-5 flex flex-col items-center gap-x-5">
        <SuccessFormIcon />
        <p class="mt-10 text-center">
          Thank you for contacting us, our team will be in touch with you
          shortly via the following details:
        </p>
      </div>
      <div class="flex flex-col gap-6 mb-3">
        <UserInfoField
          v-for="(field, key) of fields"
          :key="key"
          v-model="field.value"
          :name="key"
          :label="field.label"
          :can-edit="field.canEdit"
          :updated="field.updated"
          :is-editable="field.isEditable"
          :is-submitting="field.isSubmitting"
          @update-field="updateField"
          @on-submit="onSubmit"
        />
      </div>
    </div>
    <div class="flex flex-col items-center gap-2 w-full">
      <CommonButton class="!w-full" @click="$emit('close')">
        Close
      </CommonButton>
      <NuxtLink
        to="/privacy"
        target="_blank"
        class="text-s-900 underline text-xs text-center w-fit"
        no-prefetch
      >
        Privacy Policy
      </NuxtLink>
    </div>
  </div>
</template>
